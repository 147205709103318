<template>
  <section>
    <v-row>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Nome</h3>
          <p>{{ pessoais.nome }}</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Contato</h3>
          <p>{{ pessoais.contato | maskForCel() }}</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Aniversário</h3>
          <p>{{ pessoais.aniversario | dateFormatBr() }}</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Gênero</h3>
          <p>{{ sexos[pessoais.sexo] }}</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>CPF</h3>
          <p>{{ pessoais.cpf | cpfMask() }}</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Possui filhos?</h3>
          <p>{{ pessoais.filho == "0" ? "Não" : "Sim" }}</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Quantidade de filhos</h3>
          <p v-if="pessoais.quantidade_filhos">
            {{ pessoais.quantidade_filhos }}
          </p>
          <p v-else>Não informado</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Possui deficiência?</h3>
          <p>{{ pessoais.deficiente == "0" ? "Não" : "Sim" }}</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Deficiência</h3>
          <p v-if="pessoais.deficiencia">{{ pessoais.deficiencia }}</p>
          <p v-else>Não informado</p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Grau de deficiência</h3>
          <p v-if="pessoais.grau_deficiente">{{ pessoais.grau_deficiente }}</p>
          <p v-else>Não informado</p>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "InformacoesPessoaisView",
  data() {
    return {
      sexos: {
        M: "Masculino",
        F: "Feminino",
        L: "Lgbtqia+",
        N: "Prefiro não dizer",
      },
    };
  },
  computed: {
    pessoais: {
      get() {
        return this.$store.getters["resCandidatos/getPessoais"];
      },
    },
  },
};
</script>

<style></style>
